@import "@danielmoncada/angular-datetime-picker/assets/style/picker.min.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .btn-form-submit {
    @apply w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 dark:hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-50 dark:text-white dark:bg-blue-600 sm:col-start-2 sm:text-sm disabled:opacity-25;
  }
}

.disabled {
  opacity: 0.5;
  pointer-events: none;
}

#globeCanvas {
  margin-left:auto;
  margin-right:auto;
}

ng-scrollbar {
  width: 100% !important;
}

.ng-select.custom {
  font-size: 0.875rem;
  line-height: 1.25rem;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  border-color:black;

}

.dark .active-link {
  background-color: #2B303C !important;
  color: white !important;
}

.dark .active-link svg {
  color: #7B84A3 !important;
}


.cm-s-monokai.CodeMirror { background: rgba(31, 41, 55) !important; color: #f8f8f2 !important; border-radius:0 0 0.5rem 0.5rem;}
.cm-s-monokai div.CodeMirror-selected { background: rgba(17, 24, 39) !important; }
.cm-s-monokai .CodeMirror-gutters { background: rgba(27, 36, 48) !important; border-right: 0px; }

.fadeInDownFast {
  animation-name: fadeInDownFast;
}

@keyframes fadeInDownFast {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.dark .ng-select.custom
{
  border-radius: 5px;
  border-style: none;
}


.dark .ng-select.custom .ng-select-container  {            
  border-radius: 5px;
  background-color: #1a1c22;
  border-style: none;
  outline: none !important;
  color:white;
  border: 1px solid rgba(35, 38, 46);
}

.dark .ng-select.custom .ng-select-opened>.ng-select-container {
  background-color: #1a1c22;
  color:white;
  border: 1px solid rgba(35, 38, 46);
}

.dark .ng-select.custom .ng-dropdown-panel-items .ng-option
{
  background-color: #1a1c22;
  border-style: none;
  color:white;
  border: 1px solid rgba(35, 38, 46);


}

.dark .ng-dropdown-panel {
  background-color: #1a1c22;
  border: 1px solid rgba(35, 38, 46);
}

.dark .ng-dropdown-panel.ng-select-bottom {
  border-top-color: #1a1c22;
}

.dark .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked {
  background-color:rgba(35, 38, 46) !important;
  color:white !important;
}
.dark .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected, .ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected.ng-option-marked:hover {
  background-color:rgb(27, 30, 36) !important;
  color:white !important;
}

.dark .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: #16181d;
}

.dark .ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.right {
  border-left: 1px solid #20232a;
}


.skeleton-box {
  overflow:hidden;
}

.skeleton-box::before {
  content:'';
  animation: loading 0.9s infinite;
}

.skeleton-box::after {
  animation-delay: 0.25s !important;
  animation: loading 0.9s infinite;
}

.skeleton-box::after {
  content: '';
  position: absolute;
  background: linear-gradient(90deg, transparent, #8d929e, transparent);
  opacity:0.3;
  width: 50%;
  height: 100%;
  top: 0;
  left: 0;
}

@keyframes loading {
  0% {
    transform: skewX(-10deg) translateX(-100%);
  }
  100% {
    transform: skewX(-10deg) translateX(200%);
  }
}


.owl-dt-calendar-table .owl-dt-calendar-cell-content {
  border-radius:5px !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
  border: 1px solid rgba(229, 231, 235) !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell-selected {
  background-color:#bddaff !important;
  color:#2563eb !important;
}

.owl-dt-timer-divider {
  height: 100% !important;
}

.owl-dt-timer-divider:after {
  bottom: 45% !important;
  width:0.15em !important;
  height: 0.15em !important;
}

.owl-dt-timer-divider:before {
  top: 45% !important;
  width:0.15em !important;
  height: 0.15em !important;
}

.owl-dt-control-arrow-button svg {
  color: #7b859e !important;
}

.owl-dt-container-buttons {
  color:#2563eb !important;
}

.owl-dt-container-control-button .owl-dt-control-button-content {
  transition: none !important;
  font-size:0.8rem;
}

.owl-dt-container-control-button:hover .owl-dt-control-button-content {
  background-color:#bddaff !important;
}

.owl-dt-popup-container {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05) !important;
  border-radius:10px !important;
  border: 1px solid rgba(229, 231, 235) !important;
  font-family: 'Inter', sans-serif !important;
  overflow:hidden !important;
}

.owl-dt-calendar-table .owl-dt-calendar-cell {
  color: #101828 !important;
}

.owl-dt-timer-content .owl-dt-timer-input {
  border: 1px solid rgba(229, 231, 235) !important;
}

.owl-dt-calendar-table .owl-dt-calendar-header .owl-dt-calendar-table-divider:after {
  background: rgba(229, 231, 235) !important;
}

.owl-dt-control-button-arrow {
  display:none !important
}

.owl-dt-timer-content .owl-dt-timer-input {
  color: #101827 !important;
  background-color: #f3f4f6 !important;
}

.dark .scheme-dark {
        color-scheme:dark
}